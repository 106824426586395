/* Footer.css */
.footer-container {
    width: 100%;
    height: 55vh;
    background-color: blue;
    padding-bottom: 1vh;
}

.footer-container>img {
    display: inline-flex;
    position: relative;
    max-width: 40vh;
    height: 85%;
}

.footer-container>div {
    display: flex;
    justify-content: space-between;
    padding: 0 10vh;
    align-items: center;
    justify-content: center;
}

.footer-container span {
    color: #fff;
    font-size: 24px;
}

.footer-container span:first-child {
    margin-right: auto;
}

.footer-container span:last-child {
    margin-left: auto;
}

.insta-icon {
    width: 50px;
    height: 50px;
    margin-right: 14px;
    vertical-align: middle;
}

.footer-container a,
.footer-container a:link,
.footer-container a:visited,
.footer-container a:hover,
.footer-container a:active {
    text-decoration: none;
    color: #fff;
}

@media (max-width: 945px) {
    .footer-container>img {
        max-width: 25vh;
    }
    .footer-container>div {
        padding: 0 4vh;
        display: flex;
        flex-direction: column;
        justify-content: start;
        text-align: left;
        align-items: normal;
    }
    .footer-container>div>span {
        padding: 2px 0;
    }
    .insta-icon {
        width: 40px;
        height: 40px;
        margin-right: 14px;
        vertical-align: middle;
    }
    .footer-container span {
        color: #fff;
        font-size: 17px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: left;
    }
    .insta-name {
        display: none !important;
    }

    .footer-container span:last-child {
        margin: 0;
        right: 2vh;
        position: absolute;
    }

}