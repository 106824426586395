/* Pricing.css */
.pricing-container {
    width: 100%;
    padding: 12vh 0 0 0;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.pricing-container>img {
    display: inline-block;
    max-width: 51vh;
    height: 100%;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
}

.pricing-table tr {
    border-bottom: 4px solid blue;
}

.pricing-table th,
.pricing-table td {
    text-align: left;
    padding: 0;
    vertical-align: top;
    position: relative;
}

.pricing-table th:nth-child(1),
.pricing-table td:nth-child(1) {
    width: 40%;
}

.pricing-table th:nth-child(3),
.pricing-table th:nth-child(4),
.pricing-table td:nth-child(3),
.pricing-table td:nth-child(4) {
    width: 20%;
    text-align: center;
    min-width: 310px;
}

.pricing-table th:nth-child(2),
.pricing-table th:nth-child(4),
.pricing-table td:nth-child(2),
.pricing-table td:nth-child(4) {
    text-align: center;
}

.pricing-table th>span,
.pricing-table td>span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: blue;
    box-sizing: border-box;
}

.pricing-table th:first-child>span,
.pricing-table td:first-child>span {
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    height: 150px !important;
}

.pricing-table th:nth-child(even)>span,
.pricing-table td:nth-child(even)>span {
    background-color: blue;
    color: #fff;
    border-bottom: 4px solid white;
}

.pricing-table th span h2,
.pricing-table th span p,
.pricing-table th span .med-button,
.pricing-table td span {
    margin: 0;
}

.pricing-table th:first-child>span,
.pricing-table td:first-child>span {
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    padding-left: 9vh;
}

.pricing-table th:first-child>span>h1 {
    font-size: 14.5vw;
    line-height: 0rem;
}

.pricing-table th:first-child>span>p {
    font-size: 1.9vw;
    font-weight: 650;
}

.checks-cols {
    padding: 66px 35px;
}

.checks-cols>span,
.checks-cols>p {
    font-family: 'Kajiro';
    font-size: 14.5vw;
    line-height: 0.85em;
    font-weight: 500;
    display: block;
}

.checks-cols>span {
    font-size: 9.5vw;
    padding-bottom: 50px;
}

.med-button {
    font-family: 'Kajiro';
    font-size: 60px !important;
    font-weight: 500;
    position: relative;
    display: inline-block;
    padding: 0px 60px;
    border-radius: 200px;
    cursor: pointer;
    background-color: blue;
}

.med-button,
.med-button:link,
.med-button:visited,
.med-button:hover,
.med-button:active {
    text-decoration: none;
    color: #fff;
}

.pricing-table th:nth-child(even) .med-button {
    background-color: #fff;
}

.pricing-table th:nth-child(even) .med-button,
.pricing-table th:nth-child(even) .med-button:link,
.pricing-table th:nth-child(even) .med-button:visited,
.pricing-table th:nth-child(even) .med-button:hover,
.pricing-table th:nth-child(even) .med-button:active {
    color: blue;
}

.pricing-table td:first-child span {
    font-size: 50px;
    font-weight: 600;
    padding-top: 82px;
    padding-bottom: 82px;
}

.pricing-table td span {
    line-height: 1em;
    font-size: 38px;
    font-weight: 650;
}

.pricing-table td span>span {
    line-height: 50px;
    font-size: 38px;
    font-weight: 650;
    padding: 56px 70px;
}

.pricing-table td span>img {
    width: 100px;
    height: 120px;
    display: block;
}

.mobile-pricing-title {
    display: none;
}

.double-height {
    line-height: 2.6em !important;
}

.text-cell {
    height: 236px !important;
}


/* @media (max-width: 945px) { */
@media (max-width: 1333px) {
    .pricing-container {
        padding: 9vh 0;
    }
    .pricing-table th:first-child {
        opacity: 0;
    }

    .mobile-pricing-title {
        display: inline-block;
        color: blue;
    }

    .mobile-pricing-title h1 {
        line-height: 0;
        font-size: 22vw;
    }

    .mobile-pricing-title p {
        font-size: 4vw;
        font-weight: 650;
        margin-bottom: 10vw;
    }

    .pricing-table th,
    .pricing-table td,
    .pricing-table th:nth-child(1),
    .pricing-table td:nth-child(1) {
        width: 20%;
    }

    .pricing-table td:first-child span {
        font-size: 2.2vw;
    }

    .pricing-table th:first-child>span,
    .pricing-table td:first-child>span {
        padding-left: 1vh;
    }

    .pricing-table td span {
        line-height: 20px;
        padding: 0px;
    }

    .pricing-table td span>img {
        padding: 0px;
        height: 100px;
        width: 30px;
    }

    .pricing-table th:first-child,
    .pricing-table td:first-child {
        max-width: 90px;
    }

    .pricing-table th,
    .pricing-table td {
        max-width: 20px;
    }

    .pricing-table td span>span {
        font-size: 2.2vw;
        line-height: 18px;
        padding: 0px 10px;
    }

    .checks-cols>p {
        font-size: 13vw;
    }

    .checks-cols>span {
        font-size: 50px;
        font-size: 8.5vw;
    }

    .med-button {
        font-family: 'Kajiro';
        font-size: 30px !important;
        font-size: 6vw !important;
        line-height: 5vw;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding: 4px 4.5vw;
        border-radius: 200px;
        cursor: pointer;
        background-color: blue;
    }

    .med-button>a,
    .med-button>a:link,
    .med-button>a:visited,
    .med-button>a:hover,
    .med-button>a:active {
        text-decoration: none;
        color: #fff;
    }

    .double-height {
        line-height: 25px !important;
    }

    .dh-m {
        line-height: 50px !important;
    }

    .pricing-table td:first-child span {
        padding-top: 0px;
        padding-bottom: 0px;
        margin: auto;
        position: absolute;
        vertical-align: middle;
        justify-content: center;
        line-height: 1em;
    }

    .text-cell {
        position: relative;
        height: 100px !important;
        margin: auto !important;
    }

    .text-cell>span {
        position: absolute !important;
        margin: auto !important;
    }

    .pricing-table th:nth-child(3),
    .pricing-table th:nth-child(4),
    .pricing-table td:nth-child(3),
    .pricing-table td:nth-child(4) {
        min-width: 10px;
    }

    .pricing-table th:first-child,
    .pricing-table td:first-child {
        padding: 0;
    }
    
    .pricing-table th:first-child>span,
    .pricing-table td:first-child>span {
        height: 100px !important;
        max-height: 100px !important;
    }

    .pricing-table th:first-child>span.long-texts,
    .pricing-table td:first-child>span.long-texts {
        height: 125px !important;
        max-height: 125px !important;
    }
}

@media (max-width: 750px) {
    .med-button {
        font-family: 'Kajiro';
        font-size: 6vw !important;
        line-height: 4vw;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding: 4px 28px;
        border-radius: 22px;
        cursor: pointer;
        background-color: blue;
    }
    .pricing-table td:first-child span {
        font-size: 2.6vw;
    }
    .pricing-table td span>span {
        font-size: 2.6vw;
    }
}