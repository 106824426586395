/* Navbar.css */
.navbar-container {
    width: 100%;
    height: 20vh;
    background-color: #fff;
    position: relative;
}

.navbar-container>img {
    display: inline-block;
    max-width: 51vh;
    height: 100%;
}

@media (max-width: 945px) {
    .navbar-container {
        width: 100%;
        height: 12vh;
    }

    .navbar-container>img {
        max-width: 30vh;
        margin-top: -3px;
    }
}