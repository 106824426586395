/* Header.css */
.header-container {
    width: 100%;
    height: 80vh;
    background-color: blue;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url('../../assets/images/WEBBG2-small.jpg');
}

@media (min-width: 600px) {
    .header-container {
        background-image: url('../../assets/images/WEBBG2-medium.jpg');
    }
}

@media (min-width: 1024px) {
    .header-container {
        background-image: url('../../assets/images/WEBBG2-large.jpg');
    }
}

.header-container>div {
    background-color: blue;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.header-container>h2,
.header-container>p {
    padding: 0px;
    z-index: 2;
    text-align: center;
    color: #fff;
}

.header-container>h2 {
    font-size: 11vw;
    line-height: 9vw;
    margin-bottom: 0px;
}

.header-container>p {
    font-size: 2.6vw;
    margin-block-start: 0.8em;
    margin-block-end: 0.8em;
}

.subheader-container, .cta-container {
    width: 100%;
    height: 60vh;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subheader-container>h1,
.subheader-container>p,
.cta-container>p {
    text-align: center;
    color: blue;
}

.subheader-container>p {
    font-size: 2.6vw;
    font-weight: 750;
    padding: 0 20vw;
}

.big-button {
    font-family: 'Kajiro';
    font-size: 6.3vw;
    line-height: 6.3vw;
    background-color: blue;
    padding: 0 4vw;
    border-radius: 2.5vw;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.big-button,
.big-button:link,
.big-button:visited,
.big-button:hover,
.big-button:active {
    color: white;
}

@media (max-width: 945px) {
    .header-container {
        width: 100%;
        height: 45vh;
    }
    .header-container>h2 {
        font-size: 21vw;
        line-height: 18vw;
        margin-bottom: 0px;
        margin-top: 0px;
        padding: 0 20px;
    }

    .header-container>p {
        padding: 0 10px;
        font-size: 5vw;
    }

    .subheader-container {
        height: 45vh;
    }
    .subheader-container>p {
        font-size: 4.9vw;
        padding: 0 4vw;
        margin-block-start: 0;
        line-height: 1.15em;
        font-weight: 600;
    }

    .big-button {
        font-family: 'Kajiro';
        font-size: 14vw;
        line-height: 15vw;
        background-color: blue;
        padding: 0 40px;
        border-radius: 22px;
        cursor: pointer;
    }
    
    .big-button>a {
        color: #fff;
        text-decoration: none;
    }

    .cta-container>.big-button {
        font-size: 10vw;
        margin: 0 30px;
    }
}