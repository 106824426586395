body {
  margin: 0;
}

@font-face {
  font-family: 'Kajiro';
  src: url('./assets/fonts/Kajiro.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Epilogue';
  src: url('./assets/fonts/Epilogue-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Epilogue Italic';
  src: url('./assets/fonts/Epilogue-Italic-VariableFont_wght.ttf') format('truetype');
}

.App {
  text-align: center;
}

h1 {
  font-family: 'Kajiro';
  font-size: 220px;
  line-height: 0.9em;
  font-weight: 500;
  margin-block-start: 0.47em;
  margin-block-end: 0.47em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


h2 {
  font-family: 'Kajiro';
  font-size: 180px;
  line-height: 0.9em;
  font-weight: 500;
  margin-block-start: 0.47em;
  margin-block-end: 0.47em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


h3 {
  font-family: 'Kajiro';
  font-size: 120px;
  line-height: 0.9em;
  font-weight: 500;
  margin-block-start: 0.47em;
  margin-block-end: 0.47em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

span,
p {
  font-family: 'Epilogue';
  font-size: 30px;
  font-weight: 500;
}

@media (max-width: 945px) {
  h1 {
    font-family: 'Kajiro';
    font-size: 220px;
    line-height: 0.9em;
    font-weight: 500;
    margin-block-start: 0.47em;
    margin-block-end: 0.47em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h2 {
    font-family: 'Kajiro';
    font-size: 120px;
    line-height: 0.9em;
    font-weight: 500;
    margin-block-start: 0.47em;
    margin-block-end: 0.47em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  h3 {
    font-family: 'Kajiro';
    font-size: 120px;
    line-height: 0.9em;
    font-weight: 500;
    margin-block-start: 0.47em;
    margin-block-end: 0.47em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  span,
  p {
    font-family: 'Epilogue';
    font-size: 30px;
    font-weight: 500;
  }
}