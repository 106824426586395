/* HowItWorks.css */
.howitworks-container {
    width: 100%;
    padding: 4vh 0;
    padding-bottom: 16vh;
    background-color: blue;
    position: relative;
    color: #fff;
}

.howitworks-container>img {
    display: inline-block;
    max-width: 51vh;
    height: 100%;
}

.howitworks-container>div span {
    width: 170px;
    height: 170px;
    line-height: 190px;
    font-size: 100px;
    font-weight: 700;
    border: 4px solid #fff;
    border-radius: 500px;
    display: inline-block;
}

.howitworks-container>div p {
    font-size: 40px;
    padding: 0 60vh;
}

@media (max-width: 1600px) {
    .howitworks-container>div p {
        padding: 0 30vh;
    }
}

@media (max-width: 945px) {
    .howitworks-container {
        padding-bottom: 10vh;
    }

    .howitworks-container>h1 {
        padding-bottom: 4vw;
        font-size: 22vw;
    }
    
    .howitworks-container>div p {
        font-size: 4.5vw;
        padding: 0 2vh 2vh 2vh;
        color: #fff;
    }
    
    .howitworks-container>div span {
        width: 18vw;
        height: 18vw;
        line-height: 19.5vw;
        font-size: 5vw;
    }
    .howitworks-container h2 {
        font-size: 12vw;        
        margin-block-start: 0.27em;
        margin-block-end: 0.27em;
    }
    
}